import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import CloseIcon from "../../assets/svg/CloseIcon";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate sideNav" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="bold font15 pointer">
            <Link  activeClass="active" to="why" spy={true} smooth={true} offset={50} duration={500}  containerId="scrollContainer">Why Enleap</Link>	
        </li>
        <li className="bold font15 pointer">
            <Link  activeClass="active" containerId="contentSection" to="feature" spy={true} smooth={true} offset={50} duration={500}  containerId="scrollContainer">Service</Link>	
        </li>
        <li className="bold font15 pointer">
            <Link  activeClass="active" to="pricing" spy={true} smooth={true} offset={-80} duration={500}  containerId="scrollContainer">Pricing</Link>			
        </li>
        <li className="bold font15 pointer">
            <Link  activeClass="active" to="faq" spy={true} smooth={true} offset={-150} duration={500}  containerId="scrollContainer" >FAQ</Link>		
        </li>
       
         <li className="bold font15 pointer flexCenter " style={{marginTop: "25px"}}>
            <a className="radius8 navButton" href='https://launchpad.enleap.app/'>
                Launch App
            </a>
    
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
