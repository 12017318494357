
import React from "react";
import styled from "styled-components";
import VectorR from "../../../assets/img/picture/vector_r.png";
import Circle1 from "../../../assets/img/picture/circle1.png";
import Circle2 from "../../../assets/img/picture/circle2.png";
import Background from "../../../assets/img/picture/background.png";
import { IoShapesOutline, IoStarOutline, IoTriangleOutline, IoGridOutline, IoCubeOutline, IoEllipseOutline, IoLogoIonic, IoSquareOutline } from "react-icons/io5";

export default function FAQ() {
  return (

      <div id='feature' className="container ">
            <img src={VectorR} id='vectorR'/>
            <img src={Background} id='Background'/>
            <div class='feature-section'>
                <div class='feature-left'>
                    <div id='lbox'></div>
                    <h1 className='pageTitle'>The one stop shop for your NFT project</h1>
                    <p className='pageSubtitle'> Configure what you need and start running the services frictionless </p>
                </div>
                <div class='feature-right'>
                    <h2> NFT Launching </h2> 
                    <ul> 
                        <li><IoGridOutline/> Generate NFT </li>
                        <li><IoCubeOutline/> Configure & Deploy Smart Contract</li>
                        <li>  <IoSquareOutline className='rotate'/>  Mint Site</li>
                        <li> <IoLogoIonic/> Whitelist Management</li>
                        <li>  <IoEllipseOutline/>Secondary Marketplace Listing </li>
                    </ul>
                    <br/>
                    <h2> Community Management</h2> 
                    <ul> 
                        <li> <IoStarOutline className='rotate'/> Verification Bot</li>
                        <li> <IoTriangleOutline/> Sales Bots</li>
                        <li> <IoShapesOutline/> Rarity Ranking</li>
                    </ul>
                </div>
            </div>
            <div class='feature-section2'>
                <div class='feature-left'>
                    <h2> More Service</h2> 
                    <ul> 
                        <li> Token Launching <IoEllipseOutline/> </li>
                        <li>Staking <IoSquareOutline className='rotate'/></li>
                        <li>Raffle <IoTriangleOutline/></li>
                        <li className='inactive'>Auction <IoShapesOutline/></li>
                        <li className='inactive'>Breeding and Upgrade <IoGridOutline/></li>
                    </ul>
                </div>
                <div class='feature-right'>
                    <div id='rbox'></div>
                    <h1 className='pageTitle'>Become our premium partners to unlock more services...</h1>
                    <p className='pageSubtitle'> Reach out to us on our discord server</p>
                </div>
            </div>


         
           
      </div>

  );
}
