
import React from "react";
import styled from "styled-components";
import Circle1 from "../../../assets/img/picture/circle1.png";
import Circle2 from "../../../assets/img/picture/circle2.png";
import Enleap from "../../../assets/img/picture/enleap.png";
import WhyBg from "../../../assets/img/picture/whybg.png";
import FrameB from "../../../assets/img/picture/frame_b.png";
import FrameT from "../../../assets/img/picture/frame_t.png";
import Icon1 from "../../../assets/img/picture/icon1.png";
import Icon2 from "../../../assets/img/picture/icon2.png";
import Icon3 from "../../../assets/img/picture/icon3.png";
import Icon4 from "../../../assets/img/picture/icon4.png";
import Icon5 from "../../../assets/img/picture/icon5.png";
import Icon6 from "../../../assets/img/picture/icon6.png";

export default function FAQ() {
  return (

      <div id='why' className="container ">
            <div id='whySection'>
                <div id='whyContent'>
                    <img src={Circle1} id='wCircle2' />
                    <img src={Circle1} id='wCircle3' />
                    <img src={Circle2} id='wCircle4' />
                    <img src={Circle2} id='wCircle5' />
                    <img src={Enleap} id='enleap' />
                </div>
                <img src={WhyBg} id='whyBg' />
            </div>
            <div id='whyItemSection'>
                <div className='why-item'>
                    <img id='fb' src={FrameB}/>
                    <h2> Run first, Pay later</h2>
                    <p>You can start launching your entire collection without paying a single cent on testnet </p>
                    <img src={Icon6}/>
                </div>
                <div className='why-item'>
                    <h2> No code. </h2>
                    <p> Launch a collection with only a few clicks without needing help from devs. </p>
                    <img src={Icon5}/>
                </div>
                <div className='why-item'>
                    <h2> Most Affordable Launchpad</h2>
                    <p> Start free. Pay as low as 5% for your mint and 0% royalties. </p>
                    <img src={Icon1}/>
                </div>
                <div className='why-item'>
                    <img id='ft' src={FrameT}/>
                    <h2>One Stop Shop </h2>
                    <p> Whitelist Management, sales bot, rarity ranking and etc, we got you well covered </p>
                    <img src={Icon2}/>
                </div>
                <div className='why-item'>
                    <h2> Multi-tier Whitelist</h2>
                    <p> Distribute different tiers of whitelist with different mint price, at different time. </p>
                    <img src={Icon3}/>
                </div>
                <div className='why-item'>
                    <h2>Marketing Partnerships </h2>
                    <p> You could get various marketing support from our partners including Paras. </p>
                    <img id='ft' src={FrameT}/>
                    <img src={Icon4}/>
                </div>

            </div>
      </div>

  );
}
