// React Component 
import ReactDOM from 'react-dom';
import store from "./store/store.js";
import { Provider } from "react-redux";
import {  BrowserRouter,  Routes,  Route } from "react-router-dom";

// App 
import App from './App';

// Global Style 
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/global.css';

// Render screen
if(document.getElementById('root')){
    ReactDOM.render(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Provider store={store}><App page='landing'/></Provider>} />
                <Route path="/project/:id" element={<Provider store={store}><App page='project'/></Provider>} />
                <Route path="*" element={<div id='notFound'> 404 Not Found. </div>}/>
            </Routes>
        </BrowserRouter>,
        document.getElementById('root')
    );
}