export async function getProjectDetails(id) {
	return fetch('https://launchpad.enleap.app/api/project/' + id, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		}
	}).then(response => response.json())
}


export async function getProjectList() {
	return fetch('https://launchpad.enleap.app/api/projects', {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		}
	}).then(response => response.json())
}
