
import React from "react";
import styled from "styled-components";
import VectorL from "../../../assets/img/picture/vector_l.png";
import Circle1 from "../../../assets/img/picture/circle1.png";
import Circle2 from "../../../assets/img/picture/circle2.png";
import Rocket from "../../../assets/img/picture/rocket.gif";
import FrameT from "../../../assets/img/picture/frame_t.png";
import FrameB from "../../../assets/img/picture/frame_b.png";
import Game from "../../../assets/img/picture/game.png";
import Customise from "../../../assets/img/picture/customise.png";

export default function FAQ() {
  return (

      <div id='pricing' className="container ">
            <img src={VectorL} id='vectorL'/>
            <img id='pcircle1' src={Circle1} />
            <img id='pcircle3' src={Circle1} />
            <img id='pcircle2' src={Circle2} />
            <h1 className='pageTitle'>Pricing </h1>
            <div className='pricing-section'>
                <div className='pricing-item'>
                    <div className='pricing-image'>
                        <img src={Game}/>
                    </div>
                    <div className='pricing-content'>
                        <h2> Playground </h2>
                        <p>Test your smart contract and mint for free. Only pay when ready to go live!</p>
                        <ul>
                            <li>Smart Contract Dashboard</li>
                            <li>Testnet Launch</li>
                            <li>Generate 50 NFTs</li>
                            <li>Whitelist Mint + Management</li>
                        </ul>
                    </div>
                    <div class='pricing-btm'>
                        <div className='pricing-price'>
                            <span> $0 </span>
                        </div>
                        <div className='pricing-button'>
                            <button className='btn'> Launch App </button>
                        </div>
                    </div>
                </div>
                <div className='pricing-item'>
                    <img id='ft' src={FrameT}/>
                    <img id='fb' src={FrameB}/>
                    <div className='pricing-image rocket'>
                        <img src={Rocket} id="rocketShadow"/>
                        <img src={Rocket}/>
                    </div>
                    <div className='pricing-content'>
                        <h2> Launch </h2>
                        <p>Ready to launch? Let’s go.</p>
                        <ul>
                            <li>Everything in test package</li>
                            <li>Mainnet Launch</li>
                            <li>Unlimited number of NFT Generation</li>
                        </ul>
                    </div>
                    <div class='pricing-btm'>
                        <div className='pricing-price'>
                            <span> 8% Fee </span> 
                            <span> 1% Royalties </span>
                        </div>
                        <div className='pricing-button'>
                            <button className='btn'> Launch App </button>
                        </div>
                    </div>
                </div>
                <div className='pricing-item'>
                    <div className='pricing-image'>
                        <img src={Customise}/>
                    </div>
                    <div className='pricing-content'>
                        <h2> Customised </h2>
                        <p>Have any customized request or need some extra support, reach out to us for further discussion</p>
                        <ul>
                            <li>Customised Solution</li>
                        </ul>
                    </div>
                    <div class='pricing-btm'>
                        <div className='pricing-price'>
                        </div>
                        <div className='pricing-button'>
                            <button className='btn'> Contact Us </button>
                        </div>
                    </div>
                </div>
            </div>
      </div>

  );
}
