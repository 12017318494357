
import React from "react";
import styled from "styled-components";
import HeaderImage from "../../../assets/img/picture/landingbg.png";
import Circle1 from "../../../assets/img/picture/circle1.png";
import Circle2 from "../../../assets/img/picture/circle2.png";
import TypeAnimation from 'react-type-animation';
import {BsChevronDown} from 'react-icons/bs'
import { Link } from "react-scroll";

export default function Banner() {
  return (

      <div id='home' className="container flexSpaceCenter">
            <div id="imageWrapper">
                <img id='circle1' src={Circle1} />
                <img id='circle2' src={Circle2} />
                <img id='circle3' src={Circle1} />
                <img id='circle4' src={Circle2} />
                <img id='circle5' src={Circle2} />
                <div id='homeContent'>
                    <p> No code NFT launchpad <span> crafted for </span> </p> 
                    <TypeAnimation
                        cursor={true}
                        sequence={[
                        'Artists',
                        1500,
                        'Community',
                        1500,
                        'Startup',
                        1500,
                        'Charity',
                        1500,
                        'NFT Project ',
                        1500,
                        ]}
                        wrapper="h2"
                        repeat={Infinity}
                    />
                    <Link className='pointdown'  to="why" spy={true} smooth={true} offset={-150} duration={500}  containerId="scrollContainer" >  <BsChevronDown/></Link>					
                </div>
                <div id='homeImage'>
                    <img src={HeaderImage}  />
                    <label id='powered'> Powered by Near Tinker Union </label>
                </div>
            </div>
      </div>

  );
}
