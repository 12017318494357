
import React from "react";
import styled from "styled-components";
import VectorR from "../../../assets/img/picture/vector_r.png";
import Circle1 from "../../../assets/img/picture/circle1.png";
import Circle2 from "../../../assets/img/picture/circle2.png";
import {MdOutlineSubdirectoryArrowRight} from "react-icons/md";

export default function FAQ() {
  return (

      <div id='faq' className="container ">
            <img src={VectorR} id='vectorR'/>
            <img id='faqcircle1' src={Circle2} />
            <img id='faqcircle2' src={Circle1} />
            <h1 className='pageTitle'>FAQ </h1>
            <div className='faq-section'>
                <div className='faq-item'>
                    <h2> Do I have to host the minting website ? </h2>
                    <p> <MdOutlineSubdirectoryArrowRight/> No, we will host the minting page for you </p>
                </div>
                <div className='faq-item'>
                    <h2> How do I contact you if I or my web developer need some guidance ? </h2>
                    <p> <MdOutlineSubdirectoryArrowRight/>Reach out to our discord on <a href='https://discord.gg/rC8YuzbS4G' target="_blank"> https://discord.gg/rC8YuzbS4G </a>  (Near Tinker Union)  </p>
                </div>
                <div className='faq-item'>
                    <h2> What are the payment methods ?</h2>
                    <p>  <MdOutlineSubdirectoryArrowRight/>We accept payment in Near, USDC/USDT on Near or other blockchains </p>
                </div>
                <div className='faq-item'>
                    <h2>Who can control my smart contract once it's deployed e.g. start/pause sales, withdraw balance, etc ?</h2>
                    <p>  <MdOutlineSubdirectoryArrowRight/>Only you or someone you authorize. By default Enleap will also have the permission in order to help you setting up the project.</p>
                </div>
                <div className='faq-item'>
                    <h2> Can I change the NFT price after I deploy the smart contract? </h2>
                    <p> <MdOutlineSubdirectoryArrowRight/> Only you or someone you authorize. By default Enleap will also have the permission in order to help you setting up the project. </p>
                </div>

            </div>
      </div>

  );
}
