import React from "react";
import '../style/landing.css';
import store from "../store/store.js";
import { Provider } from "react-redux";
import Footer from "../components/Nav/Footer"
import TopNavbar from "../components/Sections/Landing/TopNavBar";
import Header from "../components/Sections/Landing/Header";
import Project from "../components/Sections/Landing/Project";
import Content from "../components/Sections/Landing/Content";
import { Scrollbar } from "react-scrollbars-custom";


export default function Landing(props) {
  return (
    <>
    <Scrollbar  scrollerProps={{
        renderer: props => {
        const { elementRef, ...restProps } = props;
        return <span {...restProps} ref={elementRef} id="scrollContainer" />;
        }
    }} style={{ width: '100vw', height: '100vh' }} > 
        <div id='landingLoader'></div>
        <Provider store={store}>
            <TopNavbar page={props.page} />
            <Content />
            <Footer />
        </Provider>
    </Scrollbar>
    </>
  );
}

