// React Component 
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setWallet, setIsWalletConnected, setUserAccountId, setUserBalance } from "./store/reducers/connectionReducer";

// NEAR Component
import { connect, WalletConnection } from "near-api-js";

// Screen Component 
import Landing from './screens/Landing';
import Project from './screens/Project';

export default function App(props) {

    // Define wallet CONST
	const dispatch= useDispatch()
    const env = useSelector(state => state.env)
    const nearConfig = useSelector(state => state.nearConfig)
    const userAccountId = useSelector(state => state.userAccountId)
	const isWalletConnected = useSelector(state => state.isWalletConnected)

    // Hook & update wallet status
    useEffect(async () => {
        const near = await connect(nearConfig);
        const wallet = new WalletConnection(near);
        dispatch(setWallet(wallet));
        dispatch(setIsWalletConnected(wallet.isSignedIn()))
        if (wallet.isSignedIn()){
            dispatch(setUserAccountId(wallet.account().accountId))
            let balance = await wallet.account().getAccountBalance();
            dispatch(setUserBalance(balance))
        } 
    }, [isWalletConnected, userAccountId]);

    // Landing page 
    if(props.page == 'landing'){

        // If testnet then return 404 
        if(env == 'testnet')
            return (<div id='notFound'> 404 Not Found. </div>);
        return (<Landing page='landing' />);
    }

    // NFT Project page 
    else if(props.page == 'project')
        return (<Project page='project'/>);

    // 404 Not Found
    else 
        return (<div id='notFound'> 404 Not Found. </div>);

}


