import React, { lazy, Suspense} from "react";
import '../style/launchpad.css';
import store from "../store/store.js";
import { Provider } from "react-redux";
import { useParams } from "react-router-dom";

export default function Project(props) {

    const { id } = useParams();
    const Content = lazy(() => import("../components/Sections/Project/Custom/" + id).catch(() =>   (import("../components/Sections/Project/Content" ))));
    const TopNav = lazy(() => import("../components/Nav/Custom/" + id).catch(() =>   (import("../components/Nav/TopNavbar" ))));

    return (
        <Suspense  fallback={<div id="screenLoader">Loading...</div>}>
            <Provider store={store}>                
                <TopNav page={props.page} />
                <Content page={props.page} />
            </Provider>
        </Suspense>
    );
}


