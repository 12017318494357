import React from "react";
import styled from "styled-components";

import AnimatedCursor from "react-animated-cursor"
import Banner from "../Landing/Banner";
import FAQ from "../Landing/FAQ";
import Pricing from "../Landing/Pricing";
import Feature from "../Landing/Feature";
import Why from "../Landing/Why";


export default function Content() {
  return (
    <div  id="contentSection">
        <AnimatedCursor  
            id='cur'
            innerSize={12}
            outerSize={35}
            color='46, 27, 210'
            outerAlpha={0.2}
            innerScale={2}
            outerScale={3} 
        />
        <Banner/>
        <Why/>
        <Feature/>
        <Pricing/>
        <FAQ/>
    </div>
  );
}

