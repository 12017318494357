// React Component 
import React, {  useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";

// Page Component
import Sidebar from "../../Nav/Sidebar";
import Backdrop from "../../Elements/Backdrop";
import Logo from "../../../assets/img/logo/logo.png";
import Discord from "../../../assets/img/picture/discord.png";
import Twitter from "../../../assets/img/picture/twitter.png";
import BurgerIcon from "../../../assets/svg/BurgerIcon";

export default function TopNavbar(props) {

    // Wallet & scroll CONST
	const [y, setY] = useState(window.scrollY);
	const [sidebarOpen, toggleSidebar] = useState(false);

    // Detect scroll 
	useEffect(() => {
		window.addEventListener("scroll", () => setY(window.scrollY));
		return () => {
			window.removeEventListener("scroll", () => setY(window.scrollY));
		};
	}, [y]);

	return (
		<>
			<Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
			{sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
			<Wrapper className="flexCenter animate" style={y > 100 ? { height: "60px", background: "#01000a" } : { height: "80px" , background: "#01000a"}}>
				<NavInner className="container flexSpaceCenter">
					<a className="pointer flexNullCenter" href='/'>
						<img src={Logo} alt="logo" style={{ width: "100px" }} />
					</a>
					<BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
						<BurgerIcon />
					</BurderWrapper>
                    <UlWrapperRight className="flexNullCenter">
                        <li className="ml-3 font14 pointer flexCenter ">
                            <Link  activeClass="active" to="why" spy={true} smooth={true} offset={50} duration={500}  containerId="scrollContainer">Why Enleap</Link>	
						</li>
                        <li className="ml-3 font14 pointer flexCenter ">
                            <Link  activeClass="active" containerId="contentSection" to="feature" spy={true} smooth={true} offset={50} duration={500}  containerId="scrollContainer">Service</Link>	
						</li>
						<li className="ml-3 font14 pointer flexCenter ">
                            <Link  activeClass="active" to="pricing" spy={true} smooth={true} offset={-80} duration={500}  containerId="scrollContainer">Pricing</Link>					
						</li>
                        <li className="ml-3 font14 pointer flexCenter ">
                            <Link  activeClass="active" to="faq" spy={true} smooth={true} offset={-150} duration={500}  containerId="scrollContainer" >FAQ</Link>						
						</li>
                        <li className="ml-3 font14 pointer flexCenter ">
                            <a target="_blank" href="http://tinkerunion.com/">Tinker Union</a>						
						</li>
                        <li className="ml-3 mr-3 font14 pointer flexCenter nav-social ">
                            <a target="_blank" href="https://discord.com/invite/9D5V5j5nWr"><img src={Discord}/></a>						
						</li>
                        <li className="ml-3 font14 pointer flexCenter nav-social">
                            <a target="_blank" href="https://twitter.com/NearTinkerUnion"><img src={Twitter}/></a>						
						</li>
						<li className="bold font14 pointer flexCenter ">
							<a className="radius8 navButton" href='https://launchpad.enleap.app/'>
								Launch App
							</a>
						</li>
					</UlWrapperRight>
				</NavInner>
			</Wrapper>
		</>
	);

}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


